
// this needs to be converted over to use the row/col system in chota
import { computed } from "vue";
import { useBlock } from "@libraryComposables/useBlock";

export default {
	name: "CoreBlockColumns",
	props: {
		index: {
			type: Number,
			default: 0,
		},
		nestedLevel: {
			type: Number,
			default: 0,
		},
		settings: {
			type: Object,
			required: true,
		},
		isEditable: {
			type: Boolean,
			default: false,
		},
	},
	setup(props) {
		const {
			bodyClasses,
			blockChildren,
			blockChildrenCount,
			blockVariantLayout,
			nestedLevelNext,
			childBlockComponent,
			hasChildren,
			hasDivider,
		} = useBlock(props);

		const blockId = computed(() => props.settings.id);
		const columnCount = computed(() => {
			let count = props.settings["column-count"];
			if (count < 2) {
				count = 2;
			}
			if (count > 6) {
				count = 6;
			}
			return count;
		});
		const sectionMainClasses = computed(() => {
			const classList = ["block__container", "block__section_main"];
			let columnCountValue = props.settings["column-count"];
			if (columnCountValue < 2) {
				columnCountValue = 2;
			}
			if (columnCountValue > 6) {
				columnCountValue = 6;
			}
			classList.push(`block__section_main--${columnCountValue}_column`);
			return classList;
		});

		const dividerClass = computed(() => {
			if (hasDivider) {
				return "hasDivider";
			}

			return "";
		});

		const isTheFirst = (index) => {
			return index == 0;
		};

		const isTheLast = (index) => {
			return blockChildrenCount.value - 1 == index;
		};

		const displayLeftDivider = computed(() => {
			return props.settings?.divider?.left;
		});

		const displayRightDivider = computed(() => {
			return props.settings?.divider?.right;
		});

		return {
			hasChildren,
			blockChildren,
			blockChildrenCount,
			blockId,
			blockVariantLayout,
			columnCount,
			nestedLevelNext,
			sectionMainClasses,
			childBlockComponent,
			bodyClasses,
			dividerClass,
			hasDivider,
			isTheFirst,
			isTheLast,
			displayLeftDivider,
			displayRightDivider,
		};
	},
};
