import { computed } from "vue";

export function useBlock(props) {
	const blockChildren = computed(() => {
		return props.settings?.blocks || [];
	});
	const blockChildrenCount = computed(() => {
		return blockChildren.value.length;
	});
	const hasChildren = computed(() => {
		return blockChildrenCount.value > 0;
	});
	const hasDivider = computed(() => {
		return props.settings?.divider && props.settings?.divider?.weight;
	});
	const blockVariantLayout = computed(() => {
		return props.settings?.variants?.layout || "rows";
	});
	const bodyClasses = computed(() => {
		const classList = ["block__body"];
		if (!hasChildren.value) {
			classList.push("block__body--empty");
		}
		return classList;
	});
	const nestedLevelNext = computed(() => {
		return props.nestedLevel + 1;
	});
	const childBlockComponent = computed(() =>
		props.isEditable ? "ForgeControlBlock" : "CoreBlock"
	);

	return {
		blockChildren,
		blockChildrenCount,
		hasChildren,
		hasDivider,
		blockVariantLayout,
		bodyClasses,
		nestedLevelNext,
		childBlockComponent,
	};
}
