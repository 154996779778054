
import { computed } from "vue";

export default {
	name: "CoreBlockVerticalDivider",
	props: {
		divider: {
			type: Object,
			default: () => {},
		},
	},
	setup(props) {
		const dividerLineHeight = computed(() => {
			let unit;
			const defaultLineHeight = "100%";
			const value = props.divider?.lineHeight;

			if (!value) return defaultLineHeight;

			if (value.includes("%")) unit = "%";
			else if (value.includes("px")) unit = "px";

			if (!unit) return defaultLineHeight;

			let numericValue = Number(value.match(/\d/g).join(""));
			return `${numericValue}${unit}`;
		});

		const dividerWidth = "24px";

		const dividerStyle = computed(() => {
			return props.divider?.style;
		});

		const dividerWeight = computed(() => {
			return props.divider?.weight;
		});

		const dividerColorClass = computed(() => {
			const color = props.divider?.color;
			if (!color) return "divider_default";

			return `divider_${color}`;
		});

		const weightConstant = {
			thin: "2px",
			medium: "6px",
			thick: "10px",
		};

		const containerStylesObject = computed(() => {
			return {
				width: dividerWidth,
				"min-width": dividerWidth,
				display: "flex",
				"justify-content": "center",
				"align-items": "center",
			};
		});

		const lineStylesObject = computed(() => {
			let lineWeight = "0px";

			if (dividerWeight.value)
				lineWeight = weightConstant[dividerWeight.value];

			return {
				height: dividerLineHeight.value,
				"background-color": "transparent",
				"border-style": `${dividerStyle.value}`,
				"border-width": `0 ${lineWeight} 0 0`,
			};
		});

		return {
			containerStylesObject,
			lineStylesObject,
			dividerColorClass,
		};
	},
};
